"use client";

import {
  GoogleAuthProvider,
  browserLocalPersistence,
  setPersistence,
  signInWithCredential,
  signInWithPopup,
  signOut,
} from "@firebase/auth";
import { toast } from "react-hot-toast";
import { reportSignInEvent } from "./analytics.ts";
import { auth, provider } from "./firebase.ts";

const popMsg = (msg: string) => {
  toast(msg, {
    duration: 3000,
    position: "bottom-center",
    id: "copy",
    // Styling
    style: {},
    className: "",

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#fff",
      secondary: "#000",
    },
    icon: "👋",
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

let loginInProgress = null as Promise<void> | null;
export const login = () => {
  if (window.isWebview) {
    return new Promise((resolve, reject) => {
      window.ReactNativeWebView?.postMessage("googleSignIn");
      // Setup a one-time event listener for the message from the native layer
      const handleMessage = (event: any) => {
        if (event.data) {
          resolve(event.data);
        } else {
          reject(new Error("Failed to sign in"));
        }
        window.removeEventListener("message", handleMessage);
      };
      window.addEventListener("message", handleMessage);
    }).then((userInfo) => {
      setPersistence(auth, browserLocalPersistence).then(async () => {
        const credentials = GoogleAuthProvider.credential(
          (userInfo as any).idToken,
        );
        const { user, operationType } = await signInWithCredential(
          auth,
          credentials,
        );
        const userName = user.displayName ?? user.email ?? "";
        if (operationType === "signIn") {
          reportSignInEvent(user);
        }
        popMsg(`שלום ${userName}`);
      });
    });
  } else {
    if (loginInProgress) {
      return loginInProgress;
    }
    loginInProgress = setPersistence(auth, browserLocalPersistence)
      .then(() =>
        signInWithPopup(auth, provider).then(({ user, operationType }) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // The signed-in user info.
          const userName = user.displayName ?? user.email ?? "unknown";

          if (operationType === "signIn") {
            reportSignInEvent(user);
          }
          popMsg(`שלום ${userName}`);
          loginInProgress = null;
        }),
      )
      .catch((error) => {
        console.error(error);
        loginInProgress = null;
      });
    return loginInProgress;
  }
};

export const logout = () => {
  popMsg("להתראות");
  return signOut(auth);
};
